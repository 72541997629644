import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 5; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/15-nov-1/pic${i}.jpg`;
    imgArray.push(imgUrl);
  }

  for (let i = 1; i <= 4; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/15-nov-1/pic${i}.jpeg`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function FunAndFrolic2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Fun & Frolic Sports Week</h1>

        <p>
          Sports has the power to change the world. Students participated
          wholeheartedly and enthusiastically.
          <br />
          <br />
          Our exhilarating Sports Week at School was filled with thrilling
          events and enthusiastic participation. The little learners from
          Pre-primary showcased their athleticism and teamwork in a series of
          exciting events like Aerobics, Hoopla walk, and Hurdle walk! The
          school grounds echoed with cheers as young athletes raced freely,
          embracing the spirit of camaraderie. Students showcased their Motor
          skills, demonstrating precision and teamwork, exhibiting their sports
          prowess and strategic play.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
